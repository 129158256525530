import Gleap from 'gleap';
import { createSlice } from '@reduxjs/toolkit';
import { getRefreshToken, removeRefreshToken } from '../../api/refreshToken';

const initialState = {
    auth: {
        user: undefined,
        access_token: undefined,
        authenticated: getRefreshToken() !== null,
    },
    deepAuth: {
        access_token: undefined,
        client_secret: undefined,
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setUser: (state, action) => {
            state.auth.user = action.payload;
            state.auth.authenticated = true;
        },
        setAccessToken: (state, action) => {
            state.auth.access_token = action.payload;
        },
        setAuth: (state, action) => {
            state.auth = action.payload;
        },
        logout: (state) => {
            window.CommandBar.shutdown();
            removeRefreshToken();
            Gleap.clearIdentity();
            state.auth.access_token = null;
            state.auth.user = undefined;
            state.auth.authenticated = false;
        },
        setDeepAuthAccessToken: (state, { payload }) => {
            state.deepAuth = payload;
        },
    },
});

export const { setUser, setAccessToken, logout, setAuth, setDeepAuthAccessToken } = authSlice.actions;

export default authSlice.reducer;
