import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedLayout = ({ child, permission, redirectPath }) => {
    const { authenticated, user } = useSelector((state) => state.auth.auth);
    const location = useLocation();
    if (!authenticated || (permission && user && !user.permissions.includes(permission))) {
        return <Navigate to={redirectPath} state={{ from: location.pathname }} replace />;
    }
    return child;
};

export default ProtectedLayout;
