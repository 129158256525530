import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import UserQuickEdit from '../../pages/UserQuickEdit';
import DeleteMember from '../../pages/DeleteMember';
import { useBoolean } from '../../hooks/use-boolean';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

export default function TeamMemberTableRow({ slno, row, isAdmin }) {
    const { firstName, lastName, email, phoneNumber, role } = row;

    const quickEdit = useBoolean();
    const quickDelete = useBoolean();

    return (
        <>
            <TableRow>
                <TableCell>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant="body2" noWrap>
                                {slno}.
                            </Typography>
                        }
                    />
                </TableCell>

                <TableCell>
                    <ListItemText
                        primary={firstName}
                        style={{ textTransform: 'capitalize' }}
                        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                        secondaryTypographyProps={{
                            component: 'span',
                            typography: 'caption',
                        }}
                    />
                </TableCell>
                <TableCell>
                    <ListItemText
                        primary={lastName}
                        style={{ textTransform: 'capitalize' }}
                        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                        secondaryTypographyProps={{
                            component: 'span',
                            typography: 'caption',
                        }}
                    />
                </TableCell>
                <TableCell>
                    <ListItemText
                        primary={email}
                        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                        secondaryTypographyProps={{
                            component: 'span',
                            typography: 'caption',
                        }}
                    />
                </TableCell>

                <TableCell>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant="body2" noWrap>
                                {phoneNumber}
                            </Typography>
                        }
                    />
                </TableCell>

                <TableCell>
                    <ListItemText
                        primary={role}
                        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                        style={{ textTransform: 'capitalize' }}
                        secondaryTypographyProps={{
                            component: 'span',
                            typography: 'caption',
                        }}
                    />
                </TableCell>

                <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <Tooltip title="Quick Edit" placement="top" arrow>
                        <IconButton
                            disabled={!isAdmin}
                            color={quickEdit.value ? 'inherit' : 'default'}
                            onClick={quickEdit.onTrue}
                        >
                            <Iconify icon="solar:pen-bold" style={isAdmin ? { color: '#2F58CD' } : {}} />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <Tooltip title="Quick Delete" placement="top" arrow>
                        <IconButton
                            disabled={!isAdmin}
                            color={quickDelete.value ? 'inherit' : 'default'}
                            onClick={quickDelete.onTrue}
                        >
                            <Iconify icon="solar:trash-bin-trash-bold" style={isAdmin ? { color: 'red' } : {}} />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <UserQuickEdit currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />
            <DeleteMember email={email} open={quickDelete.value} onClose={quickDelete.onFalse} />
        </>
    );
}

TeamMemberTableRow.propTypes = {
    row: PropTypes.object,
};
