import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOutletContext } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { CardHeader } from '@mui/material';

import { useSnackbar } from 'notistack';
import FormProvider, { RHFTextField } from '../components/hook-form';
import { useUpdateUserDetailsMutation } from '../features/api/apiSlice';

// ----------------------------------------------------------------------

export default function UserNewEditForm() {
    const [currentUser] = useOutletContext();
    const { enqueueSnackbar } = useSnackbar();
    const [updateUserDetails, { isLoading, isError, error }] = useUpdateUserDetailsMutation();

    const NewUserSchema = Yup.object().shape({
        name: Yup.string().trim().required('Name is required'),
        email: Yup.string().trim().required('Email is required').email('Email must be a valid email address'),
        phoneNumber: Yup.string()
            .trim()
            .required('Phone number is required')
            .length(10, 'Phone number must be exactly 10 characters long'),
        billingAddress: Yup.string().trim().required('Billing Address is required'),
        company: Yup.string().trim().required('Company is required'),
        gstNumber: Yup.string().trim().required('GST number is required'),
    });

    const defaultValues = useMemo(
        () => ({
            name: currentUser ? `${currentUser?.first_name} ${currentUser?.last_name}` : '',
            email: currentUser?.email || '',
            phoneNumber: currentUser?.phone_number || '',
            billingAddress: currentUser?.billing_address || '',
            company: currentUser?.company_name || '',
            gstNumber: currentUser?.company_gst_number || '',
            hasFormFilled: false,
        }),
        [currentUser]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;
    const onSubmit = handleSubmit(async (data) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            reset();
            enqueueSnackbar(currentUser ? 'Update success!' : 'Create success!');
            // router.push(paths.dashboard.user.list);
            if (data?.gstNumber && data?.phoneNumber && data?.billingAddress && data?.company) {
                data.hasFormFilled = true;
            }
            await updateUserDetails(data);
            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    });

    const gridTemplateColumnsStyle = {
        xs: 'repeat(2, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(2, 1fr)',
    };

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
                {!currentUser?.have_filled_details && (
                    <Grid xs={12} md={12}>
                        <CardHeader
                            sx={{ marginBottom: 2 }}
                            title="Account Details"
                            subheader="Fill in your business details and get started"
                        />
                        <Card sx={{ p: 3 }}>
                            <Box
                                rowGap={3}
                                columnGap={2}
                                display="grid"
                                gridTemplateColumns={{
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(2, 1fr)',
                                }}
                            >
                                <RHFTextField name="name" label="Full Name" disabled />
                                <RHFTextField name="email" label="Email Address" disabled />
                                <RHFTextField name="phoneNumber" label="Phone Number" />
                                <RHFTextField name="gstNumber" label="Company GST Number" />
                                <RHFTextField name="company" label="Company Name" />
                                <RHFTextField name="billingAddress" label="Billing Address" />
                            </Box>

                            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                    {currentUser ? 'Update changes' : 'Save Changes'}
                                </LoadingButton>
                            </Stack>
                        </Card>
                    </Grid>
                )}

                {currentUser?.have_filled_details && (
                    <Grid md={12} sm={12} xs={12}>
                        <CardHeader
                            sx={{ marginBottom: 2 }}
                            title="Account Details"
                            subheader="Here are your account details"
                        />
                        <Card sx={{ p: 6 }}>
                            <Box
                                display="grid"
                                rowGap={2}
                                gridTemplateColumns={{
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(1, 1fr)',
                                    md: 'repeat(1, 1fr)',
                                    lg: 'repeat(2, 1fr)',
                                }}
                            >
                                <Typography variant="p" display="grid" gridTemplateColumns={gridTemplateColumnsStyle}>
                                    <Typography fontWeight={600} sx={{ color: 'text.secondary' }}>
                                        Name
                                    </Typography>
                                    <Typography fontWeight={600} sx={{ color: '#35374B' }}>
                                        {`${currentUser?.first_name} ${currentUser?.last_name}`}
                                    </Typography>
                                </Typography>
                                <Typography variant="p" display="grid" gridTemplateColumns={gridTemplateColumnsStyle}>
                                    <Typography fontWeight={600} sx={{ color: 'text.secondary' }}>
                                        Email ID
                                    </Typography>
                                    <Typography fontWeight={600} sx={{ color: '#35374B' }}>
                                        {currentUser?.email}
                                    </Typography>
                                </Typography>
                                <Typography variant="p" display="grid" gridTemplateColumns={gridTemplateColumnsStyle}>
                                    <Typography fontWeight={600} sx={{ color: 'text.secondary' }}>
                                        Phone Number
                                    </Typography>
                                    <Typography fontWeight={600} sx={{ color: '#35374B' }}>
                                        {currentUser?.phone_number}
                                    </Typography>
                                </Typography>
                                <Typography variant="p" display="grid" gridTemplateColumns={gridTemplateColumnsStyle}>
                                    <Typography fontWeight={600} sx={{ color: 'text.secondary' }}>
                                        Company GST Number
                                    </Typography>
                                    <Typography fontWeight={600} sx={{ color: '#35374B' }}>
                                        {currentUser?.company_gst_number}
                                    </Typography>
                                </Typography>
                                <Typography variant="p" display="grid" gridTemplateColumns={gridTemplateColumnsStyle}>
                                    <Typography fontWeight={600} sx={{ color: 'text.secondary' }}>
                                        Company Name
                                    </Typography>
                                    <Typography fontWeight={600} sx={{ color: '#35374B' }}>
                                        {currentUser?.company_name}
                                    </Typography>
                                </Typography>
                                <Typography variant="p" display="grid" gridTemplateColumns={gridTemplateColumnsStyle}>
                                    <Typography fontWeight={600} sx={{ color: 'text.secondary' }}>
                                        Billing Address
                                    </Typography>
                                    <Typography fontWeight={600} sx={{ color: '#35374B' }}>
                                        {currentUser?.billing_address}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </FormProvider>
    );
}

UserNewEditForm.propTypes = {
    currentUser: PropTypes.object,
};
