import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Button, Alert, AlertTitle } from '@mui/material';
import { Box } from '@mui/system';
// components
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import Iconify from '../../../components/iconify';
import { setAuth } from '../../../features/auth/authSlice';
import { setRefreshToken } from '../../../api/refreshToken';
import { useLoginMutation } from '../../../features/api/publicApiSlice';
import { useUserMutation } from '../../../features/api/apiSlice';
// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const [login, { error, isLoading, isError }] = useLoginMutation();
    const [getUser, { isLoading: isUserLoading }] = useUserMutation();

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        validate: (values) => {
            return null;
        },
        onSubmit: async (values) => {
            const loginData = await login({ email: values.email, password: values.password }).unwrap();
            setRefreshToken(loginData.refresh);
            const user = await getUser().unwrap();
            dispatch(setAuth({ user, access_token: loginData.access, authenticated: true }));
            if (user) navigate('/dashboard/app');
        },
    });

    return (
        <>
            <Box my={2}>
                {isError && (
                    <Alert severity="error">
                        <AlertTitle>{error?.data?.detail || 'Something went wrong, try reloading page.'}</AlertTitle>
                        {"Check email if you haven't activated account or resend the activation link."}
                    </Alert>
                )}
            </Box>
            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
                <Stack spacing={3}>
                    <TextField
                        required
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="email"
                        label="Email address"
                    />
                    <TextField
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    {/* <Checkbox name="remember" label="Remember me" /> */}
                    <Box flexGrow={1} />
                    <Link href="/reset_password" variant="subtitle2" underline="hover">
                        Reset password?
                    </Link>
                </Stack>

                <Button
                    disabled={isLoading || isUserLoading}
                    fullWidth
                    color="secondary"
                    type="submit"
                    size="large"
                    variant="contained"
                >
                    {isLoading || isUserLoading ? <CircularProgress size={'1.5rem'} /> : 'Login'}
                </Button>
            </Box>
        </>
    );
}
