import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";

// @mui 
import { IconButton, Card, CardContent, CardHeader, Button, Typography, CardMedia, Link, Divider, TextField, Snackbar, SnackbarContent } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CloseIcon from '@mui/icons-material/Close';

// ---------------------------------------------------------

function getTimestamp() {
  const pad = (n, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
  const d = new Date();

  return `${pad(d.getFullYear(), 4)}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
}

// -----------------------------------------------------------

const Referral = () => {
  const [openSuccess, setOpenSucess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [Email, setemail] = useState('');
  const { user } = useSelector((state) => {
    return state.auth.auth;
  });
  const handleChange = (event) => {
    setemail(event.target.value);
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenSucess(false);
  };
  const action = (
    <fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </fragment>
  );

  const handleSubmit = (event) => {
    const data = {
      "ref_first_name": user.first_name,
      "ref_last_name": user.last_name,
      "ref_email": user.email,
      "ref_plan": user?.client?.client_id?.includes('free') ? 'free' : 'paid',
      "ref_client_id": user['client']['client_id'],
      "invitee_email": Email,
      "timestamp": getTimestamp()
    }
    axios.post('https://services.leadconnectorhq.com/hooks/jV0nvwefjUbmYH8mUE33/webhook-trigger/ef5c4306-16bc-4114-8125-8edf7c75d5df', data)
      .then(response => { setOpenSucess(true) }).catch(error => { setOpenError(true) });
    setemail('')

  }
  return (
    <Card sx={{ maxWidth: 1000, marginX: 'auto', paddingX: 3 }}>
      <CardHeader title={
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <Typography display="inline" variant="h5">
            Help us spread the word!
          </Typography>
          <EmojiEventsIcon sx={{ color: 'rgb(255,215,0)', marginX: 1.5, marginY: 0, padding: 0, height: 30, width: 30 }} />
        </div>
      } />
      <CardContent>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <CardMedia component="img"
            image="/assets/icons/capterra.png" sx={{ height: 30, width: 30, display: 'inline' }} />

          <Typography display="inline" sx={{ marginY: 2, marginLeft: 3 }}>
            Review us on <Link onClick={() => {
              window.open('https://hi.deepvue.tech/capterra', '_blank');
            }} component="button">Capterra</Link> and earn $10
          </Typography>
          <CardMedia component="img"
            image="/assets/icons/money-bag.svg" sx={{ height: 25, width: 25, display: 'inline' }} />
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <CardMedia component="img"
            image="/assets/icons/g2.svg" sx={{ height: 30, width: 30, display: 'inline' }} />
          <Typography display="inline" sx={{ marginY: 2, marginLeft: 3 }} >
            Review us on <Link onClick={() => {
              window.open('https://hi.deepvue.tech/g2', '_blank');
            }} component="button">G2</Link>
          </Typography>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <CardMedia component="img"
            image="/assets/icons/gbp.svg" sx={{ height: 30, width: 30, display: 'inline' }} />
          <Typography display="inline" sx={{ marginY: 2, marginLeft: 3 }} >
            Review us on <Link onClick={() => {
              window.open('https://hi.deepvue.tech/gbp', '_blank');
            }} component="button">Google Business Profile</Link>
          </Typography>
        </div>
        <Divider sx={{ marginY: 2, marginX: 'auto' }} />
        <Typography variant="h5" sx={{ marginTop: 4 }}>
          Refer DEEPVUE to a friend & EARN
        </Typography>
        <Typography  sx={{ marginBottom: 4,fontSize:14 }}>
        Explore more about our partnership and referral programs <Link onClick={() => {
              window.open('https://hi.deepvue.tech/partner-with-us', '_blank');
            }} component="button">here</Link>
        </Typography>
        
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <TextField
            id="outlined-basic"
            placeholder="Add email address"
            value={Email}
            type="email"
            onChange={handleChange}
            sx={{ width: 500, marginRight: 3 }} />
          <Button onClick={handleSubmit} size='large' variant="contained" sx={{
             boxShadow: 0,
             '&:hover': {
              backgroundColor: 'rgb(32,101,209)',
          },
          }}>Share</Button>
        </div>
      </CardContent>
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom', // Position the snackbar at the bottom
          horizontal: 'center', // Center the snackbar horizontally
        }}
      >
        <SnackbarContent
          style={{ backgroundColor: 'green' }}
          message="Referred Successfully"
          action={action}
        />
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom', // Position the snackbar at the bottom
          horizontal: 'center', // Center the snackbar horizontally
        }}
      >
        <SnackbarContent
          style={{ backgroundColor: 'red' }}
          message="Something Went Wrong!"
          action={action}
        />
      </Snackbar>
    </Card>
  );

}

export default Referral;