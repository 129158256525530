import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import WarningIcon from '@mui/icons-material/Warning';
import DialogContent from '@mui/material/DialogContent';
import { CardHeader, Typography, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDeleteTeamMemberMutation } from '../features/api/apiSlice';

export default function DeleteMember({ email, open, onClose }) {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [deleteTeamMember, { isLoading, isError, error }] = useDeleteTeamMemberMutation();

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            onClose();
            enqueueSnackbar('User deleted successfully!', { variant: 'success' });
            await deleteTeamMember({ "email": email });
            window.location.reload();
        } catch (error) {
            enqueueSnackbar('Failed to delete user.', { variant: 'error' });
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card>
                <Dialog
                    fullWidth
                    maxWidth={false}
                    open={open}
                    onClose={onClose}
                    PaperProps={{
                        sx: { maxWidth: 450 },
                    }}
                >
                    <CardHeader
                        title="Delete Member"
                        subheader="Confirmation will delete member and cannot be restored"
                    />
                    <Box>
                        <DialogContent>
                            <Box
                                sx={{
                                    backgroundColor: '#f8d7da',
                                    padding: '16px',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    border: '1px solid #f5c6cb',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    maxWidth: '400px',
                                    margin: 'auto',
                                }}
                            >
                                <IconButton
                                    sx={{
                                        backgroundColor: '#f5c6cb',
                                        color: '#721c24',
                                        marginBottom: '12px', // reduced margin
                                        '&:hover': {
                                            backgroundColor: '#f5c6cb',
                                        },
                                    }}
                                    disableRipple
                                >
                                    <WarningIcon fontSize="large" />
                                </IconButton>
                                <Typography
                                    variant="h6"
                                    sx={{ color: '#721c24', fontWeight: 'bold', marginBottom: '4px' }} // reduced margin
                                >
                                    Are you sure you want to delete
                                </Typography>
                                <Typography variant="h6" sx={{ color: '#d9534f', fontWeight: 'bold' }}>
                                    {email} ?
                                </Typography>
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button variant="outlined" onClick={onClose}>
                                Cancel
                            </Button>

                            <LoadingButton variant="contained" onClick={handleConfirm} loading={loading}>
                                Confirm
                            </LoadingButton>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Card>
        </>
    );
}

DeleteMember.propTypes = {
    email: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
