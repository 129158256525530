import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CardHeader, Modal } from '@mui/material';
import { useSnackbar } from 'notistack';
import FormProvider, { RHFTextField, RHFSelect } from '../components/hook-form';
import { useEditTeamMemberMutation } from '../features/api/apiSlice';

const Role = [
    { value: 'admin', label: 'Admin' },
    { value: 'developer', label: 'Developer' },
];

export default function UserQuickEdit({ currentUser, open, onClose }) {
    const { enqueueSnackbar } = useSnackbar();
    const [editTeamMember, { isLoading, isError, error }] = useEditTeamMemberMutation();

    const NewUserSchema = Yup.object().shape({
        firstName: Yup.string().trim().required('First Name is required'),
        lastName: Yup.string().trim().notRequired('Optional'),
        email: Yup.string().trim().required('Email is required').email('Email must be a valid email address'),
        phoneNumber: Yup.string()
            .trim()
            .required('Phone number is required')
            .length(10, 'Phone number must be exactly 10 characters long'),
        role: Yup.string().required('Role is required'),
    });

    const defaultValues = useMemo(
        () => ({
            firstName: currentUser?.firstName || '',
            lastName: currentUser?.lastName || '',
            email: currentUser?.email || '',
            phoneNumber: currentUser?.phoneNumber || '',
            role: currentUser?.role || '',
        }),
        [currentUser]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            reset();
            onClose();
            enqueueSnackbar('Edit success!');
            await editTeamMember(data);
            window.location.reload();
            console.info('DATA', data);
        } catch (error) {
            console.error(error);
        }
    });

    return (
        <>
            <Card>
                <Dialog
                    fullWidth
                    maxWidth={false}
                    open={open}
                    onClose={onClose}
                    PaperProps={{
                        sx: { maxWidth: 720 },
                    }}
                >
                    <CardHeader title="Edit Member" subheader="Edit Team Members Details" />
                    <FormProvider methods={methods} onSubmit={onSubmit}>
                        <DialogContent>
                            <Box
                                rowGap={3}
                                columnGap={2}
                                display="grid"
                                gridTemplateColumns={{
                                    xs: 'repeat(1, 1fr)',
                                    sm: 'repeat(2, 1fr)',
                                }}
                            >
                                <RHFTextField name="firstName" label="First Name" />
                                <RHFTextField name="lastName" label="Last Name" />
                                <RHFTextField name="email" label="Email Address" disabled />
                                <RHFTextField name="phoneNumber" label="Phone Number" />
                                <RHFSelect name="role" label="Role">
                                    {Role.map((role) => (
                                        <MenuItem key={role.value} value={role.value}>
                                            {role.label}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button variant="outlined" onClick={onClose}>
                                Cancel
                            </Button>

                            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                Update
                            </LoadingButton>
                        </DialogActions>
                    </FormProvider>
                </Dialog>
            </Card>
        </>
    );
}

UserQuickEdit.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    currentUser: PropTypes.object,
};
