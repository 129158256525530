import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import TransactionTable from '../sections/@dashboard/wallet/TransactionTable';
import Balance from '../sections/@dashboard/wallet/Balance';
import { useCreateRazorpayOrderMutation } from '../features/api/apiSlice';

const WalletPage = () => {
    const { user } = useSelector((state) => state.auth.auth);
    const [alert, setAlert] = useState(undefined);
    const [createRazorpayOrder] = useCreateRazorpayOrderMutation();

    const formik = useFormik({
        initialValues: {
            recharge_amount: user ? user.client.minimum_recharge_amount : 500,
        },
        enableReinitialize: true,
        validate: (values) => {
            const errors = {};
            if (values.recharge_amount < user.client.minimum_recharge_amount) {
                errors.recharge_amount = `Please enter an amount greater than or equal to ₹ ${user.client.minimum_recharge_amount.toLocaleString(
                    undefined,
                    {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }
                )}`;
            }
            return errors;
        },
        onSubmit: async (values) => {
            setAlert(undefined);
            const orderData = await createRazorpayOrder({
                amount: ((+values.recharge_amount + +((values.recharge_amount * 18) / 100)) * 100).toFixed(2),
            })
                .unwrap()
                .catch((err) => {
                    console.error(err);
                    setAlert({ severity: 'error', title: 'Something went wrong.', message: err.data.msg });
                });
            console.log(orderData);
            const { amount, currency, order_id } = orderData;
            const options = {
                key: 'rzp_live_lIsHNyYatkqwFf',
                amount: amount.toString(),
                currency,
                name: 'Deepvue.tech',
                description: 'Wallet Recharge',
                order_id,
                handler: async (response) => {
                    console.log(response);
                    setAlert({
                        severity: 'success',
                        title: 'Payment successful',
                        message: 'We are processing it, may take time.',
                    });
                },
                prefill: {
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                },
                theme: {
                    color: '#61dafb',
                },
                modal: {
                    ondismiss() {
                        setAlert({ severity: 'error', title: 'Something went wrong.', message: 'Please try again.' });
                    },
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        },
    });
    return (
        <Grid container spacing={2}>
            <Grid item lg={12} sm={12} m={2}>
                <Balance />
            </Grid>
            <Grid item sm={12} m={2}>
                <Card>
                    <CardHeader title="Recharge your wallet" subheader="Using Payment Gateway" />
                    <CardContent>
                        <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                            <Grid container flexDirection="column">
                                <Typography variant="h4">Payment Method Details</Typography>
                                <Typography variant="body2">
                                    To add funds to your Deepvue.tech wallet, enter the recharge amount and pay via
                                    Credit/Debit Card, UPI, or NetBanking.
                                </Typography>
                                <Grid item sm={4} mt={2}>
                                    {alert && (
                                        <Alert severity={alert.severity}>
                                            <AlertTitle>{alert.title}</AlertTitle>
                                            {alert.message}
                                        </Alert>
                                    )}
                                </Grid>
                                <Grid container my={4} columnSpacing={2}>
                                    <Grid item>
                                        <TextField
                                            name="recharge_amount"
                                            id="recharge_amount"
                                            label="Recharge Amount"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                },
                                                shrink: 'true',
                                                startAdornment: <InputAdornment position="start">₹ </InputAdornment>,
                                            }}
                                            onWheel={(e) => e.target.blur()}
                                            variant="outlined"
                                            required
                                            value={formik.values.recharge_amount}
                                            defaultValue={user?.client.minimum_recharge_amount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.recharge_amount !== undefined}
                                            helperText={formik.errors.recharge_amount}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container mb={2} columnSpacing={2} rowSpacing={1} flexDirection="column">
                                    <Grid item>
                                        <Typography variant="h5">Order summary</Typography>
                                    </Grid>
                                    <Grid item container justifyContent="space-between" sm={8}>
                                        <Typography variant="body1">Subtotal</Typography>
                                        <Typography variant="body1">
                                            ₹{' '}
                                            {((formik.values.recharge_amount * 100) / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item container justifyContent="space-between" sm={8}>
                                        <Typography variant="body1">GST(18%)</Typography>
                                        <Typography variant="body1">
                                            ₹{' '}
                                            {((formik.values.recharge_amount * 18) / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        sx={{ backgroundColor: '#DFE3E8', borderRadius: 1 }}
                                        item
                                        container
                                        justifyContent="space-between"
                                        my={2}
                                        sm={8}
                                        p={1}
                                        ml={2}
                                    >
                                        <Typography variant="h5">Amount Due</Typography>
                                        <Typography variant="h5">
                                            ₹{' '}
                                            {(
                                                +formik.values.recharge_amount +
                                                +((formik.values.recharge_amount * 18) / 100)
                                            ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item sm={1.5}>
                                    <Button
                                        fullWidth
                                        disabled={!formik.isValid || formik.isSubmitting}
                                        color="secondary"
                                        variant="outlined"
                                        type="submit"
                                    >
                                        {formik.isSubmitting ? <CircularProgress size={'1.5rem'} /> : 'Recharge'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12} m={2}>
                <Card>
                    <CardHeader
                        title="Transactions"
                        subheader="Please feel free to contact us if you have any inquiries."
                    />
                    <CardContent>
                        <TransactionTable />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default WalletPage;
