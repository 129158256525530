// Account.jsx
import { useState, useEffect, useCallback } from 'react';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { Outlet, useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
import { useGetUserDetailsQuery } from '../features/api/apiSlice';

const TABS = [
    {
        value: 'api-keys',
        label: 'Credentials',
        icon: <Iconify icon="bi:shield-lock-fill" width={20} />,
    },
    {
        value: 'account-info',
        label: 'Account Information',
        icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
        value: 'team-members',
        label: 'Team Members',
        icon: <Iconify icon="mdi:account-group" width={24} />,
    },
];

export default function Account() {
    const [activeTab, setActiveTab] = useState('api-keys');
    const [lastParam, setLastParam] = useState('');

    const { data } = useGetUserDetailsQuery();
    const navigate = useNavigate();

    const handleChangeTab = useCallback(
        (newValue) => {
            navigate(`/dashboard/account/${newValue}`);
            setActiveTab(newValue);
        },
        [navigate]
    );

    useEffect(() => {
        const currentURL = window.location.href;
        const params = currentURL.split('/');
        const lastParamValue = params[params.length - 1];
        setLastParam(lastParamValue);
        setActiveTab(lastParamValue);
    }, [activeTab,navigate]);

    return (
        <Container
            sx={{
                maxWidth: {
                    md: '100%',
                },
            }}
        >
            <Card
                sx={{
                    mb: 3,
                    height: 72,
                }}
            >
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        width: 1,
                        left: 0,
                        zIndex: 9,
                        pl: 2,
                        bgcolor: 'background.paper',
                        [`& .${tabsClasses.flexContainer}`]: {
                            pr: { md: 3 },
                            justifyContent: {
                                md: 'flex-start',
                            },
                            alignContent: {
                                md: 'center',
                            },
                        },
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#0E46A3',
                        },
                    }}
                >
                    {TABS.map((tab) => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            icon={tab.icon}
                            label={tab.label}
                            onClick={() => handleChangeTab(tab.value)}
                            sx={{
                                flexDirection: 'row',
                                gap: '12px',
                                color: '#637381',
                                '&.Mui-selected': {
                                    color: '#0E46A3',
                                    fontWeight: 'bold',
                                },
                                '&.Mui-focusVisible': {
                                    backgroundColor: 'black',
                                },
                                '&:hover': {
                                    color: '#0E46A3',
                                    opacity: 1,
                                },
                            }}
                        />
                    ))}
                </Tabs>
            </Card>

            <Outlet context={[data]} />
        </Container>
    );
}
