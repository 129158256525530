import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGetUdyamAadhaarRequestIdMutation, useGetUdyamAadhaarDataMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

// {
//     "source_output": {
//         "general_details": {
//             "applied_date": "2020-08-19",
//             "commencement_date": "2010-05-31",
//             "date_of_inc": "2010-05-31",
//             "dic_name": "AMBALA",
//             "enterprise_name": "PREMIUM HP CENTRE",
//             "enterprise_type": "Small",
//             "major_activity": "TRADING",
//             "msme_di": "KARNAL ",
//             "organization_type": "Proprietary",
//             "social_category": "General",
//             "state": "HARYANA"
//         },
//         "nic_details": [
//             {
//                 "nic_details_1": {
//                     "activity_type": "Services",
//                     "date": "2021-08-12",
//                     "nic_2_digit": "46 - Wholesale trade, except of motor vehicles and motorcycles",
//                     "nic_4_digit": "4661 - Wholesale of solid, liquid and gaseous fuels and related products",
//                     "nic_5_digit": "46610 - Wholesale of solid, liquid and gaseous fuels and related products"
//                 },
//                 "nic_details_2": {
//                     "activity_type": "Trading",
//                     "date": "2023-02-18",
//                     "nic_2_digit": "47 - Retail trade, except of motor vehicles and motorcycles",
//                     "nic_4_digit": "4730 - Retail sale of automotive fuel in specialized stores",
//                     "nic_5_digit": "47300 - Retail sale of automotive fuel in specialized stores [includes the activity of petrol filling stations."
//                 }
//             }
//         ],
//         "official_address": {
//             "block": "Saha",
//             "city": "Ambala",
//             "district": "AMBALA",
//             "door": "Premium HP Centre",
//             "email": "premiumhp@outlook.com",
//             "mobile": "98*****005",
//             "name_of_premises": "Premium HP Centre",
//             "pin": "133101",
//             "road": "Saha-Shahbad road",
//             "state": "HARYANA",
//             "town": "Village Bihta"
//         },
//         "status": "id_found",
//         "unit_details": [
//             {
//                 "unit_1": {
//                     "block": "0",
//                     "building": "Premium HP Centre",
//                     "city": "Ambala",
//                     "district": "AMBALA",
//                     "flat": "0",
//                     "pin": "133101",
//                     "road": "Saha-Shahbad road",
//                     "state": "HARYANA",
//                     "unit_name": "Premium HP Centre",
//                     "village": "Village Bihta"
//                 }
//             }
//         ]
//     }
// }

const delay = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

const KycUdyam = () => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [getUdyamAadhaarRequestId, { isLoading, isError, error }] = useGetUdyamAadhaarRequestIdMutation();
    const [
        getUdyamAadhaarData,
        {
            data,
            isLoading: dataIsLoading,
            isFetching: dataIsFetching,
            isError: dataIsError,
            isSuccess: dataIsSuccess,
            error: dataError,
        },
    ] = useGetUdyamAadhaarDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            setLoading(true);
            const requestData = await getUdyamAadhaarRequestId({ udyam_aadhaar_number: values.udyam }).unwrap();
            const getData = await fetch_data(requestData.request_id);
            setLoading(false);
            if (getData?.status === 'completed') setModalOpen(true);
        },
    });
    const fetch_data = async (request_id) => {
        const getData = await getUdyamAadhaarData({ request_id }).unwrap();
        console.log(getData);
        if (getData?.status === 'in_progress') {
            await delay(2000);
            return fetch_data(request_id);
        }
        return getData;
    };
    useEffect(() => {
        console.log(data);
    }, [data]);

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={2}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        Udayam Details &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Enterprise Name
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.enterprise_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Enterprise Type
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.enterprise_type || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Organization Type
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.organization_type || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Major Activity
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.major_activity || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Email
                            </Grid>
                            <Grid item>{data?.result?.source_output?.official_address?.email || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Mobile
                            </Grid>
                            <Grid item>{data?.result?.source_output?.official_address?.mobile || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of INC
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.date_of_inc || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Commencement Date
                            </Grid>
                            <Grid item>{data?.result?.source_output?.general_details?.commencement_date || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Official Address
                            </Grid>
                            <Grid item>
                                {(() => {
                                    const off_addr = data?.result?.source_output?.official_address;
                                    return off_addr
                                        ? [
                                              off_addr?.door,
                                              off_addr?.block,
                                              off_addr?.name_of_premises,
                                              off_addr?.road,
                                              off_addr?.city,
                                              off_addr?.state,
                                              off_addr?.pin,
                                          ].join(' ')
                                        : '-';
                                })()}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Udyam Aadhaar Verification"
                        subheader="By taking in the Udyam Aadhaar Id and receiving information pertaining to the Udyam Aadhaar Id, verify Udyam Aadhaar Ids."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Udyam Aadhaar Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="UDYAM-XX-00-0000000"
                                    size="small"
                                    type="text"
                                    name="udyam"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || loading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {loading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {data?.status === 'failed' && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {data?.message || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default KycUdyam;
