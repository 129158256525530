import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// gleap
import Gleap from 'gleap';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import useMediaQuery from '@mui/material/useMediaQuery';
import PaidIcon from '@mui/icons-material/Paid';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import '../../../theme/css/commandBar.css';
//
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
    color: 'black',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func,
};
const handleClick = () => {
    window.CommandBar.open();
};

export default function Header({ onOpenNav }) {
    const { user } = useSelector((state) => {
        return state.auth.auth;
    });
    const navigate = useNavigate();
    const isScreenSmall = useMediaQuery('(max-width:400px)');
    useEffect(() => {
        if (user) {
            Gleap.identify(`${user?.client?.client_id}-${user?.email.split('@')[0]}`, {
                name: user.first_name,
                email: user.email,
                customData: {
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    subscription: user?.client?.client_id?.includes('free') ? 'free' : 'paid',
                    client_id: user?.client?.client_id,
                    wallet_balance: user?.client?.wallet_balance
                        ? user.client.wallet_balance.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : undefined,
                },
            });
            const loggedInUserId = `${user?.client?.client_id}-${user?.email?.split('@')[0]}`;
            if (isScreenSmall) {
                window.CommandBar.boot(loggedInUserId, {
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    email: user?.email,
                    subscription: user?.client?.client_id?.includes('free') ? 'free' : 'paid',
                    client_id: user?.client?.client_id,
                    wallet_balance: user?.client?.wallet_balance
                        ? user.client.wallet_balance.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : undefined,
                });
            } else {
                window.CommandBar.boot(
                    loggedInUserId,
                    {
                        first_name: user?.first_name,
                        last_name: user?.last_name,
                        email: user?.email,
                        subscription: user?.client?.client_id?.includes('free') ? 'free' : 'paid',
                        client_id: user?.client?.client_id,
                        wallet_balance: user?.client?.wallet_balance
                            ? user.client.wallet_balance.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                              })
                            : undefined,
                    },
                    {
                        formFactor: {
                            type: 'inline',
                            rootElement: 'commandbar-inline-root',
                        },
                    }
                );
            }
        }
    }, [user, isScreenSmall]);
    return (
        <StyledRoot>
            <StyledToolbar>
                <IconButton
                    onClick={onOpenNav}
                    sx={{
                        mr: 1,
                        color: 'text.primary',
                        display: { lg: 'none' },
                    }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>
                {isScreenSmall ? (
                    <Box sx={{ flexGrow: 1 }} />
                ) : (
                    <Box
                        id="commandbar-inline-root"
                        sx={{
                            flexGrow: 1,
                            marginX: 'auto',
                            boxShadow: 'none',
                            maxWidth: '600px',
                            marginTop: '-41px',
                            padding: 0,
                        }}
                    />
                )}
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 1,
                        sm: 1.5,
                    }}
                >
                    {isScreenSmall ? (
                        <IconButton onClick={handleClick}>
                            <Iconify icon="eva:search-fill" />
                        </IconButton>
                    ) : null}
                    <Button
                        variant="contained"
                        endIcon={<PaidIcon />}
                        sx={{
                            marginLeft: 1.5,
                            boxShadow: 'none',
                            height: 30,
                            borderRadius: 1,
                            padding: 1,
                            '&:hover': {
                                backgroundColor: 'rgb(32,101,209)',
                            },
                        }}
                        onClick={() => {
                            navigate('/dashboard/referral/');
                        }}
                    >
                        Reward
                    </Button>

                    <Button
                        variant="contained"
                        endIcon={<DescriptionIcon />}
                        sx={{
                            marginLeft: 1.5,
                            boxShadow: 'none',
                            height: 30,
                            borderRadius: 1,
                            padding: 1,
                            '&:hover': {
                                backgroundColor: 'rgb(32,101,209)',
                            },
                        }}
                        onClick={() => {
                            window.open('https://developers.deepvue.tech/reference/developer-documentation', '_blank');
                        }}
                    >
                        API Docs
                    </Button>
                    <AccountPopover />
                </Stack>
            </StyledToolbar>
        </StyledRoot>
    );
}
