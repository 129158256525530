import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Grid, Container, Typography, Alert, AlertTitle, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useGetAggregateQuery, useGetChartDataQuery, useGetProductsQuery } from '../features/api/apiSlice';
// sections
import { AppApiUsage, AppWidgetSummary, AppFilters } from '../sections/@dashboard/app';
// import Loading from '../utils/Loading';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth.auth);
    const { dateRange, products } = useSelector((state) => state.dashboard);
    const { data: aggregateData, isError: aggregateDataError } = useGetAggregateQuery({
        until_date: dateRange.until_date,
        since_date: dateRange.since_date,
        products,
    });
    const { data: usageData, isError: usageDataError } = useGetChartDataQuery({
        until_date: dateRange.until_date,
        since_date: dateRange.since_date,
        products,
    });
    const { data: allProducts, isError: productsError, isLoading: productsIsLoading } = useGetProductsQuery();


    let successfulCallsArray = [];
    let failedCallsArray = [];
    const totalCallsArray = new Array(7).fill(0);
    
    if (usageData?.chartData?.length > 0) {
        successfulCallsArray = new Array(7).fill(0);
        failedCallsArray = new Array(7).fill(0);
    
        for (let i = 0; i < usageData.chartData.length; i += 1) {
            
            let dataArray = usageData?.chartData[i]?.data;
            const status = usageData.chartData[i].name;

            if(dataArray.length > 7){
                dataArray = dataArray.slice(-7);
            }

            if(status === 200 && dataArray.length > 0) {
                successfulCallsArray = dataArray;
            }

            if(status !== 200 && dataArray.length > 0){
                 for (let j = 0; j< dataArray.length; j+=1) {
                    failedCallsArray[j] += dataArray[j];
                 }
            }
        }
    }

    if(successfulCallsArray?.length < 7) {
        const dummyArray = [...successfulCallsArray];
            for(let i=0;i<7-successfulCallsArray.length;i+=1) {
                dummyArray.push(0);
            }
        successfulCallsArray = dummyArray;
    }

    if(failedCallsArray?.length < 7){
        const dummyArray = [...failedCallsArray];
        for(let i=0;i<7-failedCallsArray.length;i+=1) {
            dummyArray.push(0);
        }
        failedCallsArray = dummyArray;
    }

    for(let i = 0;i<7;i+=1){
        if((successfulCallsArray?.length) -1 >= i){
            totalCallsArray[i] += successfulCallsArray[i];
        }
        if((failedCallsArray?.length) - 1 >= i){
            totalCallsArray[i] += failedCallsArray[i];
        }
    }

    return (
        <>
            <Helmet>
                <title> Deepvue.tech </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid sx={{ mb: 5 }}>
                    <Typography lineHeight={1} variant="h4">
                        Hi, {user && `${user.first_name} ${user.last_name}`}
                    </Typography>
                    <Typography variant="caption" mt={0} p={0} lineHeight={0}>
                        Here is a summary of how your APIs are performing.
                    </Typography>
                </Grid>
                {/* <Grid item lg={12}>
                    <Card>
                        <Grid container justifyContent="space-between" height={200}>
                            <Grid item>First</Grid>
                            <Grid item>Second</Grid>
                        </Grid>
                    </Card>
                </Grid> */}
                <AppFilters allProducts={!allProducts?.productList ? [['All', 'All']] : allProducts?.productList} />
                {(aggregateDataError || usageDataError || productsError) && (
                    <Grid container spacing={3} pl={3}>
                        <Grid item xs={6}>
                            <Alert severity="error">
                                <AlertTitle>{'Something bad happened...'}</AlertTitle>
                                Try reloading the page.{' '}
                                <Link
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Reload
                                </Link>
                            </Alert>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item xs={12} sm={6} md={4}>
                        <AppWidgetSummary
                            title="Total Calls"
                            total={aggregateData ? aggregateData.successful + aggregateData.failed : 0}
                            color="info"
                            chart={{
                                series: totalCallsArray,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <AppWidgetSummary
                            title="Successful Calls"
                            total={aggregateData ? aggregateData.successful : 0}
                            color="success"
                            chart={{
                                colors: [theme.palette.success.light, theme.palette.success.main],
                                series: successfulCallsArray,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <AppWidgetSummary
                            title="Failed Calls"
                            total={aggregateData ? aggregateData.failed : 0}
                            color="error"
                            chart={{
                                colors: [theme.palette.error.light, theme.palette.error.main],
                                series: failedCallsArray,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <AppApiUsage
                            title="API Usage"
                            // subheader="(+43%) than last week"
                            chartLabels={usageData ? usageData.chartLabels : []}
                            chartData={usageData ? usageData.chartData : []}
                            stroke_curve="smooth"
                            y_axis_formatter={{
                                formatter: (y) => {
                                    if (typeof y !== 'undefined') {
                                        return `${y.toFixed(0)} Calls`;
                                    }
                                    return y;
                                },
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={4}>
                        <AppProductPercentage
                            title="Usage by Product"
                            chartData={[
                                { label: 'UPI', value: 4344 },
                                { label: 'Aadhar', value: 5435 },
                                { label: 'Pan', value: 1443 },
                                { label: 'Bank', value: 4443 },
                            ]}
                            chartColors={[
                                theme.palette.primary.main,
                                theme.palette.info.main,
                                theme.palette.warning.main,
                                theme.palette.error.main,
                            ]}
                        />
                    </Grid> */}
                </Grid>
            </Container>
        </>
    );
}
