import React, { useState } from 'react';
import { Box, Button, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { setDateRange, setProducts } from '../../../features/dashboard/dashboardSlice';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../theme/css/react_dates_overrides.css.css';

AppFilters.prototype = {
    allProducts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

const RenderDatePresets = () => {
    const data = [];
    return (
        <Grid flexDirection={'row'} p={2}>
            {data.map((val) => (
                <Button variant="outlined" key={val.name}>
                    {val.name}
                </Button>
            ))}
        </Grid>
    );
};

export default function AppFilters({ allProducts }) {
    const theme = useTheme();
    const { dateRange, products } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();
    const [dateRangeSelectorFocused, setDateRangeSelectorFocused] = useState(null);

    const onDatesChange = ({ startDate, endDate }) => {
        dispatch(
            setDateRange({
                until_date: endDate != null ? endDate.format('YYYY-MM-DD') : null,
                since_date: startDate != null ? startDate.format('YYYY-MM-DD') : null,
            })
        );
    };

    const onFocusChange = (focusedInput) => {
        setDateRangeSelectorFocused(focusedInput);
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        dispatch(setProducts(value));
    };
    return (
        <Grid container item xs={12} mb={2} width={'100%'} spacing={2} alignItems="center">
            <Grid item>
                <InputLabel className="date-range-label">Date Range</InputLabel>
                <DateRangePicker
                    renderCalendarInfo={RenderDatePresets}
                    onDatesChange={onDatesChange}
                    onFocusChange={onFocusChange}
                    focusedInput={dateRangeSelectorFocused}
                    startDate={moment(dateRange.since_date, 'YYYY-MM-DD')}
                    endDate={dateRange.until_date === null ? null : moment(dateRange.until_date, 'YYYY-MM-DD')}
                    isOutsideRange={() => false}
                    startDateId="Na"
                    endDateId="Na"
                    minimumNights={0}
                />
            </Grid>
            <Grid item width={200}>
                <InputLabel className="products-label">Products</InputLabel>
                <Select
                    input={<OutlinedInput size="small" />}
                    size="small"
                    value={products}
                    fullWidth
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    renderValue={(selected) => (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 0.5,
                                padding: 0.6,
                            }}
                        >
                            {selected}
                        </Box>
                    )}
                >
                    {allProducts?.map((name) => {
                        return (
                            <MenuItem key={name[0]} value={name[0]} style={getStyles(name[0], products, theme)}>
                                {name[0]}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
        </Grid>
    );
}
