export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        if (!file) {
            resolve(undefined);
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror = (error) => reject(error);
    });
