export const getColor = (status) => {
    const colorData = {
        200: '#00CA5A',
        400: '#E14D2A',
        403: '#FF6D6D',
        422: '#FD841F',
        500: '#874356',
        503: '#A64B2A',
        504: '#9E7676',
    };
    return colorData[status] || '#647E68';
};
