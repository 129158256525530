import { DOCUMENT_MAX_SIZE } from '../globals/constants';

export const validate_supported_file_formats = (values) => {
    const errors = {};

    if (values.picture1) {
        // supported formats - images and pdf
        const supported_format = /image|pdf/.test(values.picture1.type);
        const file1Size = values.picture1.size / 1024 / 1024;
        if (file1Size > DOCUMENT_MAX_SIZE) errors.picture1 = `Max file size limit: ${DOCUMENT_MAX_SIZE} MB.`;
        if (!supported_format) errors.picture1 = `Supports jpeg, jpg, png and pdf formats only.`;
    }
    if (values.picture2) {
        // supported formats - images and pdf
        const supported_format = /image|pdf/.test(values.picture1.type);
        const file2Size = values.picture2.size / 1024 / 1024;
        if (file2Size > DOCUMENT_MAX_SIZE) errors.picture2 = `Max file size limit: ${DOCUMENT_MAX_SIZE} MB.`;
        if (!supported_format) errors.picture2 = `Supports jpeg, jpg, png and pdf formats only.`;
    }

    return errors;
}           
            