import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useGstinAdvanceMutation } from '../features/api/deepApiSlice';
import { modalStyles as style } from '../utils/cssStyles';

const KycGstinAdv = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [gstinAdvance, { data, isLoading, isError, error }] = useGstinAdvanceMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await gstinAdvance({ gstin_number: values.gstin_number }).unwrap();
            setModalOpen(true);
        },
    });
    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={2}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        GSTIN Details &nbsp; <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                    </Box>
                    <Grid container mb={3} sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                GSTIN
                            </Grid>
                            <Grid item>{data?.data?.gstin || '-'}</Grid>
                        </Grid>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6} style={{ fontWeight: 'bold' }}>
                                Principal Contact Details
                            </Grid>
                        </Grid>
                        <Grid item color="gray" sm={6}>
                            Address
                        </Grid>
                        <Grid item>{data?.data?.contact_details?.principal?.address || '-'}</Grid>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Email
                            </Grid>
                            <Grid item>{data?.data?.contact_details?.principal?.email || '-'}</Grid>
                        </Grid>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Mobile
                            </Grid>
                            <Grid item>{data?.data?.contact_details?.principal?.mobile || '-'}</Grid>
                        </Grid>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Nature of Business
                            </Grid>
                            <Grid item>{data?.data?.contact_details?.principal?.nature_of_business || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem my={0.5} />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6} style={{ fontWeight: 'bold' }}>
                                Additional Contact Details
                            </Grid>
                        </Grid>
                        {Array.isArray(data?.data?.contact_details?.additional) &&
                            data?.data?.contact_details?.additional?.map((res) => {
                                return (
                                    <>
                                        <Grid container flexDirection="row" my={0.5}>
                                            <Grid item color="gray" sm={6}>
                                                Address
                                            </Grid>
                                            <Grid item>{res.address || '-'}</Grid>
                                        </Grid>
                                        <Divider light sx={{ width: '100%' }} flexItem />
                                        <Grid container flexDirection="row" my={0.5}>
                                            <Grid item color="gray" sm={6}>
                                                Email
                                            </Grid>
                                            <Grid item>{res.email || '-'}</Grid>
                                        </Grid>
                                        <Divider light sx={{ width: '100%' }} flexItem />
                                        <Grid container flexDirection="row" my={0.5}>
                                            <Grid item color="gray" sm={6}>
                                                Mobile
                                            </Grid>
                                            <Grid item>{res.mobile || '-'}</Grid>
                                        </Grid>
                                        <Divider light sx={{ width: '100%' }} flexItem />
                                        <Grid container flexDirection="row" my={0.5}>
                                            <Grid item color="gray" sm={6}>
                                                Nature of Business
                                            </Grid>
                                            <Grid item>{res.nature_of_business || '-'}</Grid>
                                        </Grid>
                                        <Divider sx={{ width: '100%' }} flexItem />
                                    </>
                                );
                            })}
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6} style={{ fontWeight: 'bold' }}>
                                Promoters
                            </Grid>
                            {Array.isArray(data?.data?.promoters) &&
                            data?.data?.promoters?.map((res) =>{
                                return (
                                    <>
                                        <Grid container flexDirection="row" my={0.5}>
                                            <Grid item color="gray" sm={6} />
                                            <Grid item>{res || '-'}</Grid>
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Annual Turnover
                            </Grid>
                            <Grid item>{data?.data?.annual_turnover || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Annual Turnover FY
                            </Grid>
                            <Grid item>{data?.data?.annual_turnover_fy || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Percentage in Cash
                            </Grid>
                            <Grid item>{data?.data?.percentage_in_cash || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Percentage in Cash FY
                            </Grid>
                            <Grid item>{data?.data?.percentage_in_cash_fy || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Aadhaar Validation
                            </Grid>
                            <Grid item>{data?.data?.aadhaar_validation || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Aadhaar Validation Date
                            </Grid>
                            <Grid item>{data?.data?.aadhaar_validation_date || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                PAN Number
                            </Grid>
                            <Grid item>{data?.data?.pan_number || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Business Name
                            </Grid>
                            <Grid item>{data?.data?.business_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Legal Name
                            </Grid>
                            <Grid item>{data?.data?.legal_name || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Center Jurisdiction
                            </Grid>
                            <Grid item>{data?.data?.center_jurisdiction || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                State Jurisdiction
                            </Grid>
                            <Grid item>{data?.data?.state_jurisdiction || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Registration
                            </Grid>
                            <Grid item>{data?.data?.date_of_registration || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Constitution of Business
                            </Grid>
                            <Grid item>{data?.data?.constitution_of_business || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Taxpayer Type
                            </Grid>
                            <Grid item>{data?.data?.taxpayer_type || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                GSTIN Status
                            </Grid>
                            <Grid item>{data?.data?.gstin_status || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Date of Cancellation
                            </Grid>
                            <Grid item>{data?.data?.date_of_cancellation || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Field Visit Conducted
                            </Grid>
                            <Grid item>{data?.data?.field_visit_conducted || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6} style={{ fontWeight: 'bold' }}>
                                Nature Bus Activities
                            </Grid>
                            {Array.isArray(data?.data?.nature_bus_activities) &&
                            data?.data?.nature_bus_activities.map((res) => {
                                return (
                                    <>
                                        <Grid container flexDirection="row" my={0.5}>
                                            <Grid item color="gray" sm={6} />
                                            <Grid item>{res || '-'}</Grid>
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Nature of core business activity code
                            </Grid>
                            <Grid item>{data?.data?.nature_of_core_business_activity_code || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Nature of core business activity description
                            </Grid>
                            <Grid item>{data?.data?.nature_of_core_business_activity_description || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6} style={{ fontWeight: 'bold' }}>
                                Filing Status
                            </Grid>
                            {Array.isArray(data?.data?.filing_status) &&
                            data?.data?.filing_status.map((res) => {
                                return (
                                    <>
                                        <Grid container flexDirection="row" my={0.5}>
                                            {Array.isArray(res) && res.map((val) => {
                                                return (
                                                    <>
                                                        <Grid container flexDirection="row" my={0.5}>
                                                            <Grid item color="gray" sm={6}>
                                                                Return Type
                                                            </Grid>
                                                            <Grid item>{val.return_type || '-'}</Grid>
                                                        </Grid>
                                                        <Grid container flexDirection="row" my={0.5}>
                                                            <Grid item color="gray" sm={6}>
                                                                Financial Year
                                                            </Grid>
                                                            <Grid item>{val.financial_year || '-'}</Grid>
                                                        </Grid>
                                                        <Grid container flexDirection="row" my={0.5}>
                                                            <Grid item color="gray" sm={6}>
                                                                Tax Period
                                                            </Grid>
                                                            <Grid item>{val.tax_period || '-'}</Grid>
                                                        </Grid>
                                                        <Grid container flexDirection="row" my={0.5}>
                                                            <Grid item color="gray" sm={6}>
                                                                Date of filing
                                                            </Grid>
                                                            <Grid item>{val.date_of_filing || '-'}</Grid>
                                                        </Grid>
                                                        <Grid container flexDirection="row" my={0.5}>
                                                            <Grid item color="gray" sm={6}>
                                                                Status
                                                            </Grid>
                                                            <Grid item>{val.status || '-'}</Grid>
                                                        </Grid>
                                                        <Grid container flexDirection="row" my={0.5}>
                                                            <Grid item color="gray" sm={6}>
                                                                Mode of filing
                                                            </Grid>
                                                            <Grid item>{val.mode_of_filing || '-'}</Grid>
                                                        </Grid>
                                                        <Divider sx={{ width: '100%' }} flexItem />
                                                    </>
                                                );
                                            }) || '-'}
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Address
                            </Grid>
                            <Grid item>{data?.data?.address || '-'}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="GSTIN Advanced Verification"
                        subheader="Verify basic GSTIN Details alongside advanced ones such as point of contact email & mobile, annual turnover and filing status and many more."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>GST Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="GSTIN"
                                    size="small"
                                    type="text"
                                    name="gstin_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default KycGstinAdv;
