import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RefreshIcon from '@mui/icons-material/Refresh';

import Label from '../label';

// ----------------------------------------------------------------------

export default function BatchTableRow({ slno, row, refreshTableData }) {
    const { date, time, referenceID, status, downloadlink } = row;
    const handleButtonClick = () => {
        if (status === 200) {
            window.open(downloadlink, '_blank');
        }
    };

    function convertToAMPM(timeStr) {
        const timeParts = timeStr.split(':');

        let hours = parseInt(timeParts[0], 10);

        const meridiem = hours < 12 ? 'AM' : 'PM';
        hours = hours % 12 || 12;

        const ampmTime = `${hours}:${timeParts[1]} ${meridiem}`;

        return ampmTime;
    }

    return (
        <>
            <TableRow>
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant="body2" noWrap>
                                {slno}.
                            </Typography>
                        }
                    />
                </TableCell>

                <TableCell>
                    <ListItemText
                        primary={date}
                        secondary={convertToAMPM(time)}
                        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                        secondaryTypographyProps={{
                            mt: 0.5,
                            component: 'span',
                            typography: 'caption',
                        }}
                    />
                </TableCell>

                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant="body2" noWrap>
                                {referenceID}
                            </Typography>
                        }
                    />
                </TableCell>

                <TableCell>
                    <Label variant="soft" color={status === 200 ? 'success' : status === 201 ? 'warning' : 'error'}>
                        {status === 200 ? 'completed' : status === 201 ? 'In progress' : 'failed'}
                    </Label>
                </TableCell>

                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemText
                        disableTypography
                        primary={
                            <Button
                                variant="text"
                                onClick={handleButtonClick}
                                disabled={status !== 200}
                                sx={{
                                    color: status === 200 ? '#2165d1' : 'text.disabled',
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <CloudDownloadIcon sx={{ marginRight: '0.5rem' }} /> {/* Add the download icon */}
                                Download
                            </Button>
                        }
                    />
                </TableCell>

                <TableCell>
                    <Button onClick={refreshTableData}>
                        <RefreshIcon />
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
}

BatchTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    onViewRow: PropTypes.func,
    row: PropTypes.object,
    selected: PropTypes.bool,
};
