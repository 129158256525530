import * as Yup from 'yup';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CardHeader } from '@mui/material';
import { useSnackbar } from 'notistack';
import FormProvider, { RHFTextField, RHFSelect } from '../components/hook-form';
import { useAddTeamMemberMutation } from '../features/api/apiSlice';

const Role = [
    { value: 'admin', label: 'Admin' },
    { value: 'developer', label: 'Developer' },
];

export default function AddNewTeamMember({ currentUser, open, onClose }) {
    const { enqueueSnackbar } = useSnackbar();
    const [addTeamMember, { isLoading }] = useAddTeamMemberMutation();
    const { user } = useSelector((state) => state.auth.auth);

    const NewUserSchema = Yup.object().shape({
        firstName: Yup.string().trim().required('First Name is required'),
        lastName: Yup.string().trim(),
        email: Yup.string().trim().required('Email is required').email('Email must be a valid email address'),
        phoneNumber: Yup.string()
            .trim()
            .required('Phone number is required')
            .length(10, 'Phone number must be exactly 10 characters long'),
        role: Yup.string().required('Role is required'),
    });

    const defaultValues = useMemo(
        () => ({
            firstName: currentUser?.firstName || '',
            lastName: currentUser?.lastName || '',
            email: currentUser?.email || '',
            phoneNumber: currentUser?.phoneNumber || '',
            role: currentUser?.role || '',
        }),
        [currentUser]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            data.adminEmail = user?.email;
            data.adminName = `${user?.first_name || ''} ${user?.last_name || ''}`;
            enqueueSnackbar('Added successfully!', { variant: 'success' });
            await addTeamMember(data).unwrap();
            reset();
            onClose();
            window.location.reload();
        } catch (error) {
            enqueueSnackbar('User already exists or something went wrong', { variant: 'error' });
            console.error(error);
        }
    });

    return (
        <Card>
            <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} PaperProps={{ sx: { maxWidth: 720 } }}>
                <CardHeader title="Add New Member" subheader="Add new member to your team" />
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <DialogContent>
                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                            }}
                        >
                            <RHFTextField name="firstName" label="First Name" />
                            <RHFTextField name="lastName" label="Last Name" />
                            <RHFTextField name="email" label="Email Address" />
                            <RHFTextField name="phoneNumber" label="Phone Number" />
                            <RHFSelect name="role" label="Role">
                                {Role.map((role) => (
                                    <MenuItem key={role.value} value={role.value}>
                                        {role.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting || isLoading}>
                            Save
                        </LoadingButton>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </Card>
    );
}
