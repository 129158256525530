import { SnackbarProvider } from 'notistack';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

// ----------------------------------------------------------------------

export default function App() {  
    return (
        <ThemeProvider>
            <SnackbarProvider>
                <ScrollToTop />
                <StyledChart />
                <Router />
            </SnackbarProvider>
        </ThemeProvider>
    );
}
