import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BusinessIcon from '@mui/icons-material/Business';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsightsIcon from '@mui/icons-material/Insights';
import LaunchIcon from '@mui/icons-material/Launch';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import { Chip, ListItemText } from '@mui/material';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import PaymentIcon from '@mui/icons-material/Payment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import SvgColor from '../svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export { default } from './NavSection';

export const getSidebarData = ({ key, user }) => {
    if (key === 'back-office')
        return [
            {
                title: 'Back',
                path: '/dashboard/home',
                icon: <ArrowBackIcon />,
                render: true,
            },
            {
                title: 'Aadhaar Card',
                path: '/dashboard/back-office/aadhar',
                icon: <FingerprintIcon />,
                render: true,
            },
            {
                title: 'Pan Card',
                path: '/dashboard/back-office/pan',
                icon: <TextSnippetIcon />,
                render: true,
            },
            {
                title: 'Driving License',
                path: '/dashboard/back-office/dl',
                icon: <DriveEtaIcon />,
                render: true,
            },
            {
                title: 'Voter Id',
                path: '/dashboard/back-office/voter',
                icon: <PermIdentityIcon />,
                render: true,
            },
            {
                title: 'Bank Cheque',
                path: '/dashboard/back-office/cheque',
                icon: <PaymentTwoToneIcon />,
                render: true,
            },
            {
                title: 'Bank Account',
                path: '/dashboard/back-office/bav',
                icon: <AccountBalanceIcon />,
                render: true,
            },
        ];
    if (key === 'verification')
        return [
            {
                title: 'Back',
                path: '/dashboard/home',
                icon: <ArrowBackIcon />,
                render: true,
            },
            {
                type: 'divider',
                dividerText: 'Verification Suite',
                sx: { my: 2 },
            },
            {
                title: 'Aadhaar Basic',
                path: '/dashboard/verification/aadhaar-basic',
                icon: <FingerprintIcon />,
                render: true,
            },
            {
                title: 'PAN Basic',
                path: '/dashboard/verification/pan',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                        />
                    </svg>
                ),
                render: true,
            },
            // {
            //     title: 'PAN Advanced',
            //     path: '/dashboard/verification/pan-adv',
            //     icon: (
            //         <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
            //             <path
            //                 fill="currentColor"
            //                 d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
            //             />
            //         </svg>
            //     ),
            //     render: true,
            // },
            {
                title: 'PAN Plus',
                path: '/dashboard/verification/pan-account',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                        />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'PAN Aadhaar Link Status',
                path: '/dashboard/verification/pan-aadhaar-link-status',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                        />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'Aadhaar Masking',
                path: '/dashboard/verification/aadhaar-masking',
                icon: <PaymentIcon />,
                render: true,
            },
            {
                title: 'Voter ID',
                path: '/dashboard/verification/voter-id',
                icon: <ContactEmergencyIcon />,
                render: true,
            },
            {
                title: 'Driving License',
                path: '/dashboard/verification/driving-license',
                icon: <DirectionsCarIcon />,
                render: true,
            },
            {
                title: 'Face Match',
                path: '/dashboard/verification/facematch',
                icon: <RememberMeIcon />,
                render: true,
            },
            {
                title: 'Liveness Check',
                path: '/dashboard/verification/liveness-check',
                icon: <SensorOccupiedIcon />,
                render: true,
            },
            {
                title: 'Name Match',
                path: '/dashboard/verification/name-match',
                icon: <JoinInnerIcon />,
                render: true,
            },
            {
                title: 'Vehicle RC Advanced',
                path: '/dashboard/verification/rc-adv',
                icon: <LocalShippingIcon />,
                render: true,
            },
            {
                title: 'IFSC',
                path: '/dashboard/verification/ifsc',
                icon: <AccountBalanceIcon />,
                render: true,
            },
            {
                title: 'Bank Account',
                path: '/dashboard/verification/bank-account',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="m17.8 21.2l-2.8-3l1.2-1.2l1.6 1.6l3.6-3.6l1.2 1.4l-4.8 4.8M13 10h-3v7h2.1c.1-.8.5-1.6.9-2.3V10m3 0v2.3c.6-.2 1.3-.3 2-.3c.3 0 .7 0 1 .1V10h-3m-3.9 9H2v3h11.5c-.7-.8-1.2-1.9-1.4-3M21 6l-9.5-5L2 6v2h19V6M7 17v-7H4v7h3Z"
                        />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'UPI',
                path: '/dashboard/verification/upi',
                icon: <QrCode2Icon />,
                render: true,
            },
            {
                title: 'UPI Advanced',
                path: '/dashboard/verification/upi-advanced',
                icon: <QrCode2Icon />,
                render: true,
            },
            {
                title: 'Mobile to VPA',
                path: '/dashboard/verification/mobile-to-vpa',
                icon: <PhonelinkLockIcon />,
                render: true,
            },
            {
                title: 'Mobile to Name',
                path: '/dashboard/verification/mobile-to-name',
                icon: <PhonelinkLockIcon />,
                render: true,
            },
            {
                title: 'Mobile to Pan',
                path: '/dashboard/verification/mobile-to-pan',
                icon: <PhonelinkLockIcon />,
                render: true,
            },
            // {
            //     title: 'Mobile to Driving License',
            //     path: '/dashboard/verification/mobile-to-driving-license',
            //     icon: <PhonelinkLockIcon />,
            //     render: true,
            // },
            {
                title: 'Mobile to Uan',
                path: '/dashboard/verification/mobile-to-uan',
                icon: <PhonelinkLockIcon />,
                render: true,
            },
            {
                title: 'Mobile to Uan List',
                path: '/dashboard/verification/mobile-to-uan-list',
                icon: <PhonelinkLockIcon />,
                render: true,
            },
            {
                title: 'Mobile to Digital Age',
                path: '/dashboard/verification/mobile-to-digital-age',
                icon: <PhonelinkLockIcon />,
                render: true,
            },
            {
                title: 'Mobile to Network Details',
                path: '/dashboard/verification/mobile-to-network-details',
                icon: <PhonelinkLockIcon />,
                render: true,
            },
            {
                title: 'Mobile to Multiple UPI',
                path: '/dashboard/verification/mobile-to-multiple-upi',
                icon: <PhonelinkLockIcon />,
                render: true,
            },
            {
                title: 'Aadhaar to Uan',
                path: '/dashboard/verification/aadhaar-to-uan',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                        <rect x="7" y="11" width="10" height="10" rx="2" ry="2" fill="currentColor" />
                        <path
                            d="M12 11V7a3 3 0 1 0-6 0v4"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <circle cx="12" cy="5" r="3" fill="currentColor" />
                        <path d="M12 8a4 4 0 0 1 4 4v1H8v-1a4 4 0 0 1 4-4z" fill="currentColor" />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'Pan to Uan',
                path: '/dashboard/verification/pan-to-uan',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                        <rect x="7" y="11" width="10" height="10" rx="2" ry="2" fill="currentColor" />
                        <path
                            d="M12 11V7a3 3 0 1 0-6 0v4"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <circle cx="12" cy="5" r="3" fill="currentColor" />
                        <path d="M12 8a4 4 0 0 1 4 4v1H8v-1a4 4 0 0 1 4-4z" fill="currentColor" />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'Uan To Employment History',
                path: '/dashboard/verification/uan-to-employ-history',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                        <rect x="7" y="11" width="10" height="10" rx="2" ry="2" fill="currentColor" />
                        <path
                            d="M12 11V7a3 3 0 1 0-6 0v4"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <circle cx="12" cy="5" r="3" fill="currentColor" />
                        <path d="M12 8a4 4 0 0 1 4 4v1H8v-1a4 4 0 0 1 4-4z" fill="currentColor" />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'GSTIN',
                path: '/dashboard/verification/gstin',
                icon: <BusinessIcon />,
                render: true,
            },
            {
                title: 'GSTIN Advanced',
                path: '/dashboard/verification/gstin-adv',
                icon: <BusinessIcon />,
                render: true,
            },
            {
                title: 'GSTIN By Pan',
                path: '/dashboard/verification/gstin-by-pan',
                icon: <BusinessIcon />,
                render: true,
            },
            {
                title: 'Udyam Aadhaar',
                path: '/dashboard/verification/udyam',
                icon: <BusinessIcon />,
                render: true,
            },
            {
                title: 'Udyog Aadhaar',
                path: '/dashboard/verification/udyog',
                icon: <BusinessIcon />,
                render: true,
            },
            {
                title: 'CIN Verification',
                path: '/dashboard/verification/cin-verification',
                icon: <ApartmentIcon />,
                render: true,
            },
            {
                title: 'DIN Verification',
                path: '/dashboard/verification/din-verification',
                icon: <ApartmentIcon />,
                render: true,
            },
            {
                title: 'TDS Complaince Check',
                path: '/dashboard/verification/tds-complaince',
                icon: <CreditScoreIcon />,
                render: true,
            },
            {
                title: 'PAN MSME Check',
                path: '/dashboard/verification/pan-msme',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                            fill="currentColor"
                            d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
                        />
                    </svg>
                ),
                render: true,
            },
            {
                type: 'divider',
                dividerText: 'Digitisation Suite',
                sx: { my: 2 },
            },
            {
                title: 'Aadhaar Card OCR',
                path: '/dashboard/verification/aadhaar-ocr',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48">
                        <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.203 34.166c1.819-1.586 2.186-1.04 2.219 0"
                        />
                        <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.145 34.144c4.717-5.278 9.096-5.775 9.109.043"
                        />
                        <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.066 34.208c9.193-11.63 16.22-7.997 16.21.02"
                        />
                        <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M30.446 34.229c1.005-12.376-11.421-14.644-19.613-4.797m-.739.929c-.54.67-.827 1.085-.993 1.48"
                        />
                        <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M33.764 34.271c1.225-12.544-11.736-19.803-22.127-9.341m12.349 7.122l2.938-.74"
                        />
                        <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M36.892 34.271h2.916c-.1-7.11-3.933-11.71-10.398-14.54c3.052 2.318 8.067 6.414 7.482 14.54Zm-29.989.085L3.5 34.313c.888-.257 1.753-2.686 3.635-2.768c-.35.937-.446 1.874-.232 2.81ZM10.96 23.45c-1.34 1.468-2.343 3.105-2.895 4.967c-1.605-.85-1.026-3.905-1.923-4.438c.911.472 2.579-1.643 4.818-.528Zm2.24-2.155c1.379-1.446 2.95-2.56 4.946-2.938c-1.134-1.569-3.748-1.3-4.438-1.902c.016 1.6-.856 3.089-.507 4.84Zm7.968-3.699c1.977-.467 3.884-.419 5.748-.042c-.415-2.221-1.96-2.783-2.79-3.91c-.53.994-3.014 2.093-2.958 3.952h0Zm13.252-1.141c-1.871.94-3.022.506-4.355 2.008c1.92.498 3.568 1.404 4.925 2.747c.445-1.664-.46-3.198-.57-4.755Zm2.725 6.975c1.504 1.345 2.352 3.102 2.96 5.008c1.021-1.382 1.06-3.178 1.986-4.417c-1.585.166-3.255-1.177-4.946-.592Zm3.741 8.073a7.31 7.31 0 0 1 .38 2.832l3.234-.085c-.787-1.222-1.981-2.145-3.614-2.747Z"
                        />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'PAN Card OCR',
                path: '/dashboard/verification/pan-ocr',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48">
                        <g fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="4">
                            <rect width="40" height="32" x="4" y="8" rx="2" />
                            <path d="M17 25a4 4 0 1 0 0-8a4 4 0 0 0 0 8Z" />
                            <path strokeLinecap="round" d="M23 31a6 6 0 0 0-12 0m17-11h8m-6 8h6" />
                        </g>
                    </svg>
                ),
                render: true,
            },
            {
                title: 'Driving License OCR',
                path: '/dashboard/verification/driving-license-ocr',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 2048 1536">
                        <path
                            fill="currentColor"
                            d="M896 1084q0 55-31.5 93.5T789 1216H363q-44 0-75.5-38.5T256 1084q0-54 7.5-100.5t24.5-90t51-68.5t81-25q64 64 156 64t156-64q47 0 81 25t51 68.5t24.5 90T896 1084zM768 640q0 80-56 136t-136 56t-136-56t-56-136t56-136t136-56t136 56t56 136zm1024 416v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704q14 0 23 9t9 23zm-384-256v64q0 14-9 23t-23 9h-320q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h320q14 0 23 9t9 23zm384 0v64q0 14-9 23t-23 9h-192q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h192q14 0 23 9t9 23zm0-256v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704q14 0 23 9t9 23zm128 832V256H128v1120q0 13 9.5 22.5t22.5 9.5h1728q13 0 22.5-9.5t9.5-22.5zm128-1216v1216q0 66-47 113t-113 47H160q-66 0-113-47T0 1376V160Q0 94 47 47T160 0h1728q66 0 113 47t47 113z"
                        />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'Voter Id OCR',
                path: '/dashboard/verification/voter-id-ocr',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48">
                        <g fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="4">
                            <rect width="40" height="32" x="4" y="8" rx="2" />
                            <path d="M17 25a4 4 0 1 0 0-8a4 4 0 0 0 0 8Z" />
                            <path strokeLinecap="round" d="M23 31a6 6 0 0 0-12 0m17-11h8m-6 8h6" />
                        </g>
                    </svg>
                ),
                render: true,
            },
            {
                title: 'Bank Cheque OCR',
                path: '/dashboard/verification/cheque-ocr',
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M5 14h14v1H5v-1m16 3V8H3v9h18M1 5h22v14H1V5m4 5h7v2H5v-2Z" />
                    </svg>
                ),
                render: true,
            },
            {
                title: 'GSTIN Certificate OCR',
                path: '/dashboard/verification/gstin-ocr',
                icon: <BusinessIcon />,
                render: true,
            },
            // {
            //     title: 'Document Authenticity',
            //     path: '/dashboard/verification/documentocr',
            //     icon: <FileCopyIcon />,
            //     render: true,
            // },
            // {
            //     title: 'Photocopy Check',
            //     path: '/dashboard/verification/photocopy',
            //     icon: <FileCopyIcon />,
            //     render: true,
            // },
            // {
            //     title: 'Voter Id',
            //     path: '/dashboard/back-office/voter',
            //     icon: <PermIdentityIcon />,
            //     render: true,
            // },
            // {
            //     title: 'Bank Cheque',
            //     path: '/dashboard/back-office/cheque',
            //     icon: <PaymentTwoToneIcon />,
            //     render: true,
            // },
        ];
    return [
        {
            title: 'home',
            path: '/dashboard/home',
            icon: <HomeIcon />,
            render: true,
        },
        {
            title: 'dashboard',
            path: '/dashboard/app',
            icon: icon('ic_analytics'),
            render: true,
        },
        {
            title: 'account',
            path: '/dashboard/account',
            icon: <AccountBoxRoundedIcon />,
            render: true,
        },
        {
            title: 'API Logs',
            path: '/dashboard/logs',
            icon: <TableChartTwoToneIcon />,
            render: true,
        },
        {
            title: 'Reports',
            path: '/dashboard/reports',
            icon: <SummarizeTwoToneIcon />,
            render: true,
        },
        {
            title: 'API Status',
            path: 'https://status.deepvue.tech',
            icon: <InsightsIcon />,
            render: true,
            info: <LaunchIcon sx={{ mr: 1 }} />,
        },
        {
            title: 'Wallet',
            path: '/dashboard/wallet',
            icon: <AccountBalanceWalletTwoToneIcon />,
            info: (
                <ListItemText disableTypography sx={{ flexGrow: 0.1 }}>
                    <Chip
                        color="info"
                        sx={{
                            cursor: 'pointer',
                            borderRadius: 1,
                        }}
                        label={`₹ ${
                            user?.client?.wallet_balance
                                ? user.client.wallet_balance.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                  })
                                : 0
                        }`}
                    />
                </ListItemText>
            ),
            render: user?.permissions.includes('wallet'),
        },
        {
            title: 'Back Office',
            path: '/dashboard/back-office/aadhar',
            icon: <AccountBalanceWalletTwoToneIcon />,
            render: user?.permissions.includes('back_office'),
            info: <ArrowForwardIcon />,
        },
        {
            title: 'Verification Suite',
            path: '/dashboard/verification/aadhaar-basic',
            icon: <VerifiedUserIcon />,
            render: user?.permissions.includes('verification_suite'),
            info: (
                <ListItemText disableTypography sx={{ flexGrow: 0.1 }}>
                    <Chip
                        color="warning"
                        sx={{
                            cursor: 'pointer',
                            borderRadius: 1,
                            opacity: 0.8,
                        }}
                        label={`Try now`}
                    />
                </ListItemText>
            ),
        },
    ];
};
