import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// mocks_
import { logout } from '../../../features/auth/authSlice';

// ----------------------------------------------------------------------

const isValidUrl = (urlString) => {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

const MENU_OPTIONS = [
    {
        label: 'Home',
        icon: 'eva:home-fill',
        url: '/dashboard/app',
    },
    {
        label: 'Credentials',
        icon: 'eva:person-fill',
        url: '/dashboard/account/api-keys',
    },
    {
        label: 'Api Docs',
        icon: 'eva:person-fill',
        url: 'https://developers.deepvue.tech/reference/developer-documentation',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const { user } = useSelector((state) => state.auth.auth);
    const [open, setOpen] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClick = (url) => {
        setOpen(null);
        if (isValidUrl(url)) {
            window.open(url, '_blank');
        } else navigate(url);
    };
    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: 1,
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3),
                        },
                    }),
                }}
            >
                {/* <Avatar alt="photoURL"> */}
                <Person2OutlinedIcon
                    sx={{
                        width: 30,
                        height: 30,
                        borderRadius: 1,
                        backgroundColor: 'rgb(85,85,116,0.8)',
                    }}
                    htmlColor="white"
                />
                {/* </Avatar> */}
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user && `${user.first_name} ${user.last_name}`}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user && user.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            onClick={() => {
                                handleClick(option.url);
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={() => {
                        dispatch(logout());
                    }}
                    sx={{ m: 1 }}
                >
                    Logout
                </MenuItem>
            </Popover>
        </>
    );
}
