import { Button, Card, CardHeader, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tab from '@mui/material/Tab';
import { alpha } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import { useSelector } from 'react-redux';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import '../theme/css/fileInput.css';
import Scrollbar from '../components/scrollbar';
import TeamMemberTableRow from '../components/table/team-members-table-row';
import Label from '../components/label/Label';
import { useTable, TableNoData, TableHeadCustom, TablePaginationCustom } from '../components/table';
import Iconify from '../components/iconify';
import AddNewTeamMember from './AddNewTeamMember';
import { useBoolean } from '../hooks/use-boolean';
import { useGetTeamMembersQuery } from '../features/api/apiSlice';

const TABLE_HEAD = [
    { id: 'slNo', label: 'Sl.No' },
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'phoneNumber', label: 'PhoneNumber' },
    { id: 'role', label: 'Role' },
    { id: '' },
    { id: '' },
];

const FILTER_OPTIONS = [
    { value: 'all', label: 'All' },
    { value: 'admin', label: 'Admin' },
    { value: 'developer', label: 'Developer' },
];

const TeamMembers = () => {
    const [activeTab, setActiveTab] = useState('all');
    const addMemberDialog = useBoolean();
    const [tableData, setTableData] = useState([]);
    const [isAdmin, setAdmin] = useState(false);

    const { data: Data } = useGetTeamMembersQuery();
    const { user } = useSelector((state) => {
        return state.auth.auth;
    });

    useEffect(() => {
        if (Data) {
            setTableData(Data);
        }
    }, [Data]);

    const teamMembersList = useMemo(() => {
        return tableData.map((item) => {
            return {
                firstName: item.first_name,
                lastName: item.last_name,
                email: item.email,
                phoneNumber: item.phone_number,
                role: item.role,
            };
        });
    }, [tableData, user?.email]);

    useEffect(() => {
        const currentUser = tableData.find((item) => item.email === user?.email && item.role === 'admin');
        if (currentUser) {
            setAdmin(true);
        } else {
            setAdmin(false);
        }
    }, [tableData, user?.email]);

    const [dataFiltered, setDataFiltered] = useState(teamMembersList);

    useEffect(() => {
        handleFilter(activeTab);
    }, [teamMembersList, activeTab]);

    const table = useTable({ defaultOrderBy: 'createDate' });

    const denseHeight = table.dense ? 56 : 56 + 20;

    const notFound = !dataFiltered.length;

    const handleFilter = useCallback(
        (newValue) => {
            setActiveTab(newValue);

            if (newValue === 'all') {
                setDataFiltered(teamMembersList);
            } else {
                setDataFiltered(teamMembersList.filter((user) => user.role === newValue));
            }
        },
        [teamMembersList]
    );

    return (
        <>
            <Grid container style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <Grid item>
                    <CardHeader title="Team Members" subheader="Details of your team members" />
                </Grid>
                <Grid item style={{ textAlign: 'right', paddingRight: '5%' }}>
                    <Button
                        sx={{ padding: 1, marginTop: '40px' }}
                        variant="contained"
                        onClick={addMemberDialog.onTrue}
                        startIcon={<Iconify icon="mingcute:add-line" />}
                        disabled={!isAdmin}
                    >
                        New User
                    </Button>
                </Grid>
            </Grid>
            <AddNewTeamMember open={addMemberDialog.value} onClose={addMemberDialog.onFalse} />
            <Tabs
                value={activeTab}
                onChange={(event, newValue) => handleFilter(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                    px: 2.5,
                    boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
            >
                {FILTER_OPTIONS.map((tab) => (
                    <Tab
                        key={tab.value}
                        iconPosition="end"
                        value={tab.value}
                        label={tab.label}
                        icon={
                            <Label
                                variant={((tab.value === 'all' || tab.value === activeTab) && 'filled') || 'soft'}
                                color={
                                    (tab.value === 'admin' && 'success') ||
                                    (tab.value === 'developer' && 'warning') ||
                                    'default'
                                }
                            >
                                {tab.value === 'all'
                                    ? teamMembersList.length
                                    : teamMembersList.filter((user) => user.role === tab.value).length}
                            </Label>
                        }
                    />
                ))}
            </Tabs>
            {dataFiltered.length > 0 ? (
                <Container style={{ marginTop: '25px', maxWidth: '100%' }}>
                    <Card>
                        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                            <Scrollbar>
                                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                                    <TableHeadCustom headLabel={TABLE_HEAD} />
                                    <TableBody>
                                        {dataFiltered
                                            .slice(
                                                table.page * table.rowsPerPage,
                                                table.page * table.rowsPerPage + table.rowsPerPage
                                            )
                                            .map((row, index) => (
                                                <TeamMemberTableRow
                                                    key={index + 1}
                                                    slno={index + 1}
                                                    row={row}
                                                    isAdmin={isAdmin}
                                                />
                                            ))}
                                    </TableBody>
                                </Table>
                            </Scrollbar>
                        </TableContainer>
                        <TablePaginationCustom
                            count={dataFiltered.length}
                            page={table.page}
                            rowsPerPage={table.rowsPerPage}
                            onPageChange={table.onChangePage}
                            onRowsPerPageChange={table.onChangeRowsPerPage}
                            dense={table.dense}
                            onChangeDense={table.onChangeDense}
                        />
                    </Card>
                </Container>
            ) : (
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800, marginTop: 3 }}>
                    <TableHeadCustom headLabel={TABLE_HEAD} />
                    <TableNoData notFound={notFound} />
                </Table>
            )}
        </>
    );
};

export default TeamMembers;
