import { useState, useEffect, useCallback } from 'react';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { Outlet, useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';

const TABS = [
    {
        value: 'bav',
        label: 'Bank Account verification',
        icon: (
            <div style={{ position: 'relative', width: 24, height: 24 }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ position: 'absolute', top: 0, left: 0 }}
                >
                    <path
                        fill="currentColor"
                        d="m17.8 21.2l-2.8-3l1.2-1.2l1.6 1.6l3.6-3.6l1.2 1.4l-4.8 4.8M13 10h-3v7h2.1c.1-.8.5-1.6.9-2.3V10m3 0v2.3c.6-.2 1.3-.3 2-.3c.3 0 .7 0 1 .1V10h-3m-3.9 9H2v3h11.5c-.7-.8-1.2-1.9-1.4-3M21 6l-9.5-5L2 6v2h19V6M7 17v-7H4v7h3Z"
                    />
                </svg>
                <Iconify icon="mdi:check-bold" width={12} style={{ position: 'absolute', bottom: 0, right: 0 }} />
            </div>
        ),
    },
    {
        value: 'batch-bav',
        label: 'Batch',
        icon: <Iconify icon="mdi:file-document-outline" width={24} />,
    },
];

export default function BankAccount() {
    const [activeTab, setActiveTab] = useState('bav');
    const [lastParam, setLastParam] = useState('');
    const navigate = useNavigate();

    const handleChangeTab = useCallback(
        (newValue) => {
            navigate(`/dashboard/verification/bank-account/${newValue}`);
            setActiveTab(newValue);
        },
        [navigate]
    );

    useEffect(() => {
        const currentURL = window.location.href;
        const params = currentURL.split('/');
        const lastParamValue = params[params.length - 1];
        setLastParam(lastParamValue);
        setActiveTab(lastParamValue);
    }, [activeTab, navigate]);

    return (
        <Container
            sx={{
                maxWidth: {
                    md: '100%',
                },
            }}
        >
            <Card
                sx={{
                    mb: 3,
                    height: 72,
                }}
            >
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    sx={{
                        width: 1,
                        left: 0,
                        zIndex: 9,
                        pl: 2,
                        bgcolor: 'background.paper',
                        [`& .${tabsClasses.flexContainer}`]: {
                            pr: { md: 3 },
                            justifyContent: {
                                md: 'flex-start',
                            },
                            alignContent: {
                                md: 'center',
                            },
                        },
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#0E46A3',
                        },
                    }}
                >
                    {TABS.map((tab) => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            icon={tab.icon}
                            label={tab.label}
                            onClick={() => handleChangeTab(tab.value)} // Call handleChangeTab on click
                            sx={{
                                flexDirection: 'row',
                                gap: '12px',
                                color: '#637381',
                                '&.Mui-selected': {
                                    color: '#0E46A3',
                                    fontWeight: 'bold',
                                },
                                '&.Mui-focusVisible': {
                                    backgroundColor: 'black',
                                },
                                '&:hover': {
                                    color: '#0E46A3',
                                    opacity: 1,
                                },
                            }}
                        />
                    ))}
                </Tabs>
            </Card>

            <Outlet />
        </Container>
    );
}
