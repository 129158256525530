import { Backdrop, Box, Grid } from '@mui/material';
import React from 'react';
import ReactLoading from 'react-loading';

const Loading = ({ type, color, width }) => {
    return (
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
            <ReactLoading type={type} color={color} width={`${width}rem`} />
        </Box>
    );
};

export default Loading;
