import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Divider, List, ListItemText } from '@mui/material';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { getSidebarData } from '.';

// ----------------------------------------------------------------------
NavSection.propTypes = {
    data: PropTypes.array,
};

const isValidUrl = (urlString) => {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

export default function NavSection({ ...other }) {
    const location = useLocation();
    const [data, setData] = useState([]);
    const { user } = useSelector((state) => {
        return state.auth.auth;
    });

    useEffect(() => {
        let key = 'default';
        if (location.pathname.includes('back-office')) {
            key = 'back-office';
        } else if (location.pathname.includes('verification')) {
            key = 'verification';
        }
        setData(getSidebarData({ user, key }));
        return () => {};
    }, [user, location]);

    return (
        <Box {...other}>
            <List disablePadding sx={{ p: 1 }}>
                {data.map((item) => {
                    if (item?.type === 'divider') {
                        return (
                            <Divider sx={item?.sx} key={item.dividerText}>
                                {item.dividerText}
                            </Divider>
                        );
                    }
                    return item.render && <NavItem key={item.title} item={item} />;
                })}
            </List>
        </Box>
    );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
};

function NavItem({ item }) {
    const { title, path, icon, info } = item;

    return (
        <StyledNavItem
            component={RouterLink}
            to={path}
            target={isValidUrl(path) && '_blank'}
            sx={{
                mb: 0.5,
                border: '1px solid rgb(194, 194, 194,0.3);',
                '&.active': {
                    border: '1px solid black',
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                },
            }}
        >
            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
            <ListItemText disableTypography primary={title} />
            {/* Add global styles for info components */}
            {info && info}
        </StyledNavItem>
    );
}
