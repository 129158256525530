// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Card, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fNumber } from '../../../utils/formatNumber';
import Chart from '../../../components/chart';

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    sx: PropTypes.object,
    chart: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, chart, color = 'primary', sx, ...other }) {
    const theme = useTheme();

    const { colors = [theme.palette.primary.light, theme.palette.primary.main], series, options } = chart;

    const chartOptions = {
        colors: colors.map((colr) => colr[1]),
        fill: {
            type: 'gradient',
            gradient: {
                colorStops: [
                    { offset: 0, color: colors[0], opacity: 1 },
                    { offset: 100, color: colors[1], opacity: 1 },
                ],
            },
        },
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '68%',
                borderRadius: 2,
            },
        },
        tooltip: {
            x: { show: false },
            y: {
                formatter: (value) => fNumber(value),
                title: {
                    formatter: () => '',
                },
            },
            marker: { show: false },
        },
        ...options,
    };

    return (
        <Card
            sx={{
                py: 5,
                display: 'flex',
                alignItems: 'center',
                boxShadow: 10,
                color: (theme) => theme.palette[color].darker,
                bgcolor: (theme) => theme.palette[color].lighter,
                p: 3,
                ...sx,
            }}
            {...other}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{title}</Typography>

                <Typography variant="h3">{fNumber(total)}</Typography>
            </Box>

            <Chart dir="ltr" type="bar" series={[{ data: series }]} options={chartOptions} width={60} height={36} />
        </Card>
    );
}
